import { performFetchRequest } from './common';

export const verifyOperatorAccess = async (jwt) => {
  if (typeof jwt === 'undefined') {
    return null;
  }

  return await performFetchRequest('/admin/verify-operator-access', {
    headers: {
      'x-access-token': jwt,
    },
  });
};

export const verifyStatsAccess = async (jwt) => {
  if (typeof jwt === 'undefined') {
    return null;
  }

  return await performFetchRequest('/admin/verify-stats-access', {
    headers: {
      'x-access-token': jwt,
    },
  });
};

export const awardFreeEntries = async ({
  jwt,
  emailAddress,
  firstName,
  lastName,
  numberOfEntries,
  freeTicketType,
  rafflePrizeGroup,
  minilottoDrawId,
  sendEmail,
  lotteryDrawType,
  jackpotBoostRequested,
}) => {
  return await performFetchRequest('/admin/award-free-entries', {
    method: 'POST',
    headers: {
      'x-access-token': jwt,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      emailAddress,
      firstName,
      lastName,
      numberOfEntries,
      freeTicketType,
      rafflePrizeGroup,
      minilottoDrawId,
      sendEmail,
      lotteryDrawType,
      jackpotBoostRequested,
    }),
  });
};

export const fetchPlayer = async (jwt, emailAddress, playerId) => {
  return await performFetchRequest('/admin/fetch-player', {
    method: 'POST',
    headers: {
      'x-access-token': jwt,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      emailAddress,
      playerId,
    }),
  });
};

export const fetchSubscriptionsForPlayer = async (jwt, userId, productType) => {
  return await performFetchRequest(
    `/admin/fetch-subscriptions-for-player?userId=${userId}&productType=${productType}`,
    {
      headers: {
        'x-access-token': jwt,
      },
    }
  );
};

export const fetchSubscriptionsHistoryForSubscriptionId = async (
  jwt,
  subscriptionId
) => {
  return await performFetchRequest(
    `/admin/fetch-subscription-history?subscriptionId=${subscriptionId}`,
    {
      headers: {
        'x-access-token': jwt,
      },
    }
  );
};

export const fetchTicketOrdersForPlayer = async (jwt, userId) => {
  return await performFetchRequest(
    `/admin/fetch-ticket-orders-for-player?userId=${userId}`,
    {
      headers: {
        'x-access-token': jwt,
      },
    }
  );
};

export const processRefund = async (jwt, ticketOrderId) => {
  return await performFetchRequest('/admin/process-refund', {
    method: 'POST',
    headers: new Headers({
      'x-access-token': jwt,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      ticketOrderId,
    }),
  });
};

export const processTicketRefund = async (
  jwt,
  ticketOrderId,
  ticketId,
  refundAmount,
  refundedBy
) => {
  return await performFetchRequest('/admin/process-ticket-refund', {
    method: 'POST',
    headers: new Headers({
      'x-access-token': jwt,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      ticketOrderId,
      ticketId,
      refundAmount,
      refundedBy,
    }),
  });
};

export const fetchLotteryPrizesForPlayer = async (jwt, userId) => {
  return await performFetchRequest(
    `/admin/fetch-lottery-prizes-for-player?userId=${userId}`,
    {
      headers: {
        'x-access-token': jwt,
      },
    }
  );
};

export const fetchRafflePrizesForPlayer = async (jwt, userId) => {
  return await performFetchRequest(
    `/admin/fetch-raffle-prizes-for-player?userId=${userId}`,
    {
      headers: {
        'x-access-token': jwt,
      },
    }
  );
};

export const fetchNonDrawPrizesForPlayer = async (jwt, userId) => {
  return await performFetchRequest(
    `/admin/fetch-non-draw-prizes-for-player?userId=${userId}`,
    {
      headers: {
        'x-access-token': jwt,
      },
    }
  );
};

export const fetchAllRafflePrizeWins = async (jwt) => {
  return await performFetchRequest('/admin/fetch-all-raffle-prize-wins', {
    headers: {
      'x-access-token': jwt,
    },
  });
};

export const fetchAllMinilottoPrizeWins = async (jwt) => {
  return await performFetchRequest('/admin/fetch-all-minilotto-prize-wins', {
    headers: {
      'x-access-token': jwt,
    },
  });
};

export const fetchUnawardedPrizeWins = async (jwt, showOrderForm) => {
  return await performFetchRequest(
    `/admin/fetch-unawarded-prize-wins?showOrderForm=${showOrderForm}`,
    {
      headers: {
        'x-access-token': jwt,
      },
    }
  );
};

export const fetchUnawardedPrizes = async (jwt, nonDrawPrizes) => {
  return await performFetchRequest(
    `/admin/fetch-unawarded-prizes?nonDrawPrizes=${nonDrawPrizes}`,
    {
      headers: {
        'x-access-token': jwt,
      },
    }
  );
};

export const fetchDrawIdsForDrawsWithPrizeWins = async (jwt) => {
  return await performFetchRequest('/admin/draws-with-prize-wins', {
    headers: {
      'x-access-token': jwt,
    },
  });
};

export const fetchPrizeWinsForDrawId = async (jwt, drawId) => {
  return await performFetchRequest(
    `/admin/prize-wins-for-draw?drawId=${drawId}`,
    {
      headers: {
        'x-access-token': jwt,
      },
    }
  );
};

export const fetchAwardedHighValuedPrizeWins = async (jwt) => {
  return await performFetchRequest('/admin/awarded-high-valued-prize-wins', {
    headers: {
      'x-access-token': jwt,
    },
  });
};

export const updateNotesForPrizeWin = async (jwt, id, prizeWinType, notes) => {
  return await performFetchRequest(
    `/admin/update-notes-for-prize-win?id=${id}`,
    {
      method: 'POST',
      headers: {
        'x-access-token': jwt,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        notes,
        prizeWinType,
      }),
    }
  );
};

export const updateOrderNumberForPrizeWin = async (jwt, id, prizeWinType, orderNumber) => {
  return await performFetchRequest(
    `/admin/update-order-number-for-prize-win?id=${id}`,
    {
      method: 'POST',
      headers: {
        'x-access-token': jwt,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        orderNumber,
        prizeWinType,
      }),
    }
  );
};

export const updatePartialPayoutPercentage = async (
  jwt,
  pwid,
  newPercentage
) => {
  return await performFetchRequest(
    `/admin/update-partial-payout-percentage-for-prize-win?pwid=${pwid}`,
    {
      method: 'POST',
      headers: {
        'x-access-token': jwt,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        newPercentage,
      }),
    }
  );
};

export const updateStateOfLotteryPrizeWin = async (jwt, pwid, state) => {
  return await performFetchRequest(
    `/admin/update-state-of-lottery-prize-win?pwid=${pwid}&state=${state}`,
    {
      method: 'POST',
      headers: {
        'x-access-token': jwt,
      },
    }
  );
};

export const updateStateOfRafflePrizeWin = async (jwt, rpwid, state) => {
  return await performFetchRequest(
    `/admin/update-state-of-raffle-prize-win?rpwid=${rpwid}&state=${state}`,
    {
      method: 'POST',
      headers: {
        'x-access-token': jwt,
      },
    }
  );
};

export const updateStateOfMinilottoPrizeWin = async (jwt, mpwid, state) => {
  return await performFetchRequest(
    `/admin/update-state-of-minilotto-prize-win?mpwid=${mpwid}&state=${state}`,
    {
      method: 'POST',
      headers: {
        'x-access-token': jwt,
      },
    }
  );
};

export const updateStateOfNonDrawPrizeWin = async (jwt, id, state) => {
  return await performFetchRequest(
    `/admin/update-state-of-non-draw-prize-win?id=${id}&state=${state}`,
    {
      method: 'POST',
      headers: {
        'x-access-token': jwt,
      },
    }
  );
};

export const markPrizeWinsAsConfirmed = async (jwt, prizeWinIds) => {
  return await performFetchRequest('/admin/mark-prize-wins-as-confirmed', {
    method: 'POST',
    headers: {
      'x-access-token': jwt,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      prizeWinIds,
    }),
  });
};

// Triggered from admin page after Callum calls winners of high-valued prizes.
export const sendPrizeWinEmail = async (jwt, drawId, userId) => {
  console.log(drawId, userId);
  if (!drawId || !userId)
    return {
      successful: false,
      reason: 'Missing drawId or userId',
    };
  return await performFetchRequest('/admin/send-prizewin-email', {
    method: 'POST',
    headers: {
      'x-access-token': jwt,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      drawId,
      userId,
      ignoreNotified: true,
    }),
  });
};

export const cancelSubscription = async (jwt, subscriptionId, productType) => {
  return await performFetchRequest(
    `/admin/cancel-subscription?subscriptionId=${subscriptionId}&productType=${productType}`,
    {
      method: 'POST',
      headers: {
        'x-access-token': jwt,
      },
    }
  );
};

export const processSubscription = async (jwt, subscriptionId, productType) => {
  return await performFetchRequest(
    `/admin/process-subscription?subscriptionId=${subscriptionId}&productType=${productType}`,
    {
      method: 'POST',
      headers: {
        'x-access-token': jwt,
      },
    }
  );
};

// Draw operation.

export const prepareDraw = async (jwt) => {
  return await performFetchRequest('/admin/prepare-draw', {
    method: 'POST',
    headers: {
      'x-access-token': jwt,
    },
  });
};

export const runFridayDraw = async (jwt) => {
  return await performFetchRequest('/admin/run-friday-draw', {
    method: 'POST',
    headers: {
      'x-access-token': jwt,
    },
  });
};


export const runDraw = async (
  jwt,
  drawResultString,
  mainNumbersProofUrl,
  bonusNumberProofUrl
) => {
  return await performFetchRequest('/admin/run-draw', {
    method: 'POST',
    headers: {
      'x-access-token': jwt,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      drawResultString,
      mainNumbersProofUrl,
      bonusNumberProofUrl,
    }),
  });
};

export const completeDraw = async (jwt) => {
  return await performFetchRequest('/admin/finish-draw', {
    method: 'POST',
    headers: {
      'x-access-token': jwt,
    },
  });
};

export const notifyParticipants = async (jwt, drawId) => {
  return await performFetchRequest(
    `/admin/notify-participants?drawId=${drawId}`,
    {
      method: 'POST',
      headers: {
        'x-access-token': jwt,
      },
    }
  );
};

export const notifyWinners = async (jwt, drawId) => {
  return await performFetchRequest(`/admin/notify-winners?drawId=${drawId}`, {
    method: 'POST',
    headers: {
      'x-access-token': jwt,
    },
  });
};

export const processSubscriptions = async (jwt, subscriptionProductType) => {
  return await performFetchRequest(
    `/async-processing/process-subscriptions?subscriptionProductType=${subscriptionProductType}`,
    {
      method: 'POST',
      headers: {
        'x-access-token': jwt,
      },
    }
  );
};

export const postSalesReport = async () => {
  return await performFetchRequest('/analytics/post-daily-sales-report');
};

export const runMinilottos = async (jwt) => {
  return await performFetchRequest('/admin/manually-run-minilotto-draws', {
    method: 'POST',
    headers: {
      'x-access-token': jwt,
    },
  });
};

export const postPrizeChoicesReport = async () => {
  return await performFetchRequest('/analytics/weekly-prize-choices');
};

export const getCrmEmailTemplateNames = async (jwt) => {
  return await performFetchRequest('/admin/fetch-crm-templates', {
    headers: {
      'x-access-token': jwt,
    },
  });
};

export const triggerCrmEmail = async (
  jwt,
  crmEmail,
  testEmail,
  testRecipients
) => {
  return await performFetchRequest(
    `/admin/trigger-crm-email?crmEmail=${crmEmail}&testEmail=${testEmail}`,
    {
      method: 'POST',
      headers: {
        'x-access-token': jwt,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        testRecipients: testRecipients,
      }),
    }
  );
};

export const processAsyncQueue = async (channel, invocationId) => {
  return await performFetchRequest(
    `/async-processing/process-queue-items?channel=${channel}&invocationId=${invocationId}`,
    {
      method: 'POST',
    }
  );
};

export const fetchChannels = async (jwt) => {
  return await performFetchRequest('/async-processing/fetch-channels', {
    headers: {
      'x-access-token': jwt,
    },
  });
};

export const awardPointsToUserForAction = async (jwt, userId, action) => {
  return await performFetchRequest('/admin/award-points-to-user-for-action', {
    method: 'POST',
    headers: new Headers({
      'x-access-token': jwt,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      userId,
      action,
    }),
  });
};

export const awardTokensToUser = async (jwt, userId, tokenCount) => {
  return await performFetchRequest('/admin/award-tokens-to-user', {
    method: 'POST',
    headers: new Headers({
      'x-access-token': jwt,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      userId,
      tokenCount,
      transactionType: 'ISSUED_BY_ADMIN',
    }),
  });
};

export const pauseAsyncQueue = async (jwt) => {
  return await performFetchRequest('/async-processing/pause-async-queue', {
    method: 'POST',
    headers: new Headers({
      'x-access-token': jwt,
    }),
  });
};

// Prize management

export const uploadPrizePhoto = async (jwt, file, imageType) => {
  const formData = new FormData();
  formData.append('photo', file, file.name);

  return await performFetchRequest(
    `/admin/upload-prize-photo?imageType=${imageType}`,
    {
      method: 'POST',
      headers: new Headers({
        'x-access-token': jwt,
      }),
      body: formData,
    }
  );
};

export const storeNewPrize = async (jwt, prizeDetails) => {
  return await performFetchRequest('/admin/store-new-prize', {
    method: 'POST',
    headers: new Headers({
      'x-access-token': jwt,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      prizeDetails,
    }),
  });
};

export const storeNewRafflePrize = async (jwt, prizeDetails) => {
  return await performFetchRequest('/admin/store-new-raffle-prize', {
    method: 'POST',
    headers: new Headers({
      'x-access-token': jwt,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      prizeDetails,
    }),
  });
};

export const updatePrize = async (jwt, prizeInstanceId, prizeDetails) => {
  return await performFetchRequest(
    `/admin/update-prize?prizeInstanceId=${prizeInstanceId}`,
    {
      method: 'POST',
      headers: new Headers({
        'x-access-token': jwt,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        prizeDetails,
      }),
    }
  );
};

export const updateRafflePrize = async (
  jwt,
  rafflePrizeInstanceId,
  prizeDetails
) => {
  return await performFetchRequest(
    `/admin/update-raffle-prize?rafflePrizeInstanceId=${rafflePrizeInstanceId}`,
    {
      method: 'POST',
      headers: new Headers({
        'x-access-token': jwt,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        prizeDetails,
      }),
    }
  );
};

export const updateMinilottoPrize = async (
  jwt,
  minilottoPrizeId,
  prizeDetails
) => {
  return await performFetchRequest(
    `/admin/update-minilotto-prize?minilottoPrizeId=${minilottoPrizeId}`,
    {
      method: 'POST',
      headers: new Headers({
        'x-access-token': jwt,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        prizeDetails,
      }),
    }
  );
};

export const fetchPrizes = async (jwt) => {
  return await performFetchRequest('/admin/fetch-prizes', {
    headers: new Headers({
      'x-access-token': jwt,
    }),
  });
};

export const fetchMinilottoPrizes = async (jwt) => {
  return await performFetchRequest('/admin/fetch-minilotto-prizes', {
    headers: new Headers({
      'x-access-token': jwt,
    }),
  });
};

export const fetchPoundRafflePrizes = async (jwt) => {
  return await performFetchRequest('/admin/fetch-pound-raffle-prizes', {
    headers: new Headers({
      'x-access-token': jwt,
    }),
  });
};

export const fetchOtherSZRafflePrizes = async (jwt) => {
  return await performFetchRequest('/admin/fetch-other-sz-raffle-prizes', {
    headers: new Headers({
      'x-access-token': jwt,
    }),
  });
};

export const duplicateRafflePrize = async (jwt, id) => {
  return await performFetchRequest(`/admin/duplicate-raffle-prize?id=${id}`, {
    method: 'POST',
    headers: new Headers({
      'x-access-token': jwt,
    }),
  });
};

export const deleteRafflePrize = async (jwt, id) => {
  return await performFetchRequest(`/admin/delete-raffle-prize?id=${id}`, {
    method: 'POST',
    headers: new Headers({
      'x-access-token': jwt,
    }),
  });
};

// Differs from the ./prizes api call in that this one will also retrieve hidden prizes.
export const fetchPrizeBySlug = async (jwt, slug) => {
  return await performFetchRequest(`/admin/fetch-prize-by-slug?slug=${slug}`, {
    headers: new Headers({
      'x-access-token': jwt,
    }),
  });
};

export const fetchPrizePageOrderings = async (jwt) => {
  return await performFetchRequest('/admin/fetch-prize-page-orderings', {
    headers: new Headers({
      'x-access-token': jwt,
    }),
  });
};

export const addPrizePageOrdering = async (
  jwt,
  pageSection,
  prizeInstanceId
) => {
  return await performFetchRequest('/admin/add-prize-page-ordering', {
    method: 'POST',
    headers: new Headers({
      'x-access-token': jwt,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      pageSection,
      prizeInstanceId,
    }),
  });
};

export const removePrizePageOrdering = async (
  jwt,
  pageSection,
  prizeInstanceId
) => {
  return await performFetchRequest('/admin/remove-prize-page-ordering', {
    method: 'POST',
    headers: new Headers({
      'x-access-token': jwt,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      pageSection,
      prizeInstanceId,
    }),
  });
};

export const movePrize = async (
  jwt,
  pageSection,
  prizeInstanceId,
  decreaseOrderIndex = false
) => {
  return await performFetchRequest('/admin/increment-prize-page-order-index', {
    method: 'POST',
    headers: new Headers({
      'x-access-token': jwt,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      pageSection,
      prizeInstanceId,
      increment: decreaseOrderIndex ? -1 : 1,
    }),
  });
};

export const canPrizePageOrderIndexBeEdited = async (
  jwt,
  pageSection,
  prizeInstanceId
) => {
  return await performFetchRequest(
    '/admin/can-prize-page-order-index-be-edited',
    {
      method: 'POST',
      headers: new Headers({
        'x-access-token': jwt,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        pageSection,
        prizeInstanceId,
      }),
    }
  );
};

export const closeAccount = async (jwt, userId) => {
  return await performFetchRequest('/admin/close-account', {
    method: 'POST',
    headers: new Headers({
      'x-access-token': jwt,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      userId,
      sendEmail: true,
    }),
  });
};

export const disableAllEmailsForUser = async (jwt, userId) => {
  return await performFetchRequest('/admin/disable-emails-for-user', {
    method: 'POST',
    headers: new Headers({
      'x-access-token': jwt,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      userId,
    }),
  });
};

export const deleteAccount = async (jwt, userId) => {
  return await performFetchRequest('/admin/delete-account', {
    method: 'POST',
    headers: new Headers({
      'x-access-token': jwt,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      userId,
    }),
  });
};

export const autoRedeemExpiredTokens = async () => {
  return await performFetchRequest('/admin/auto-redeem-expired-tokens');
};

export const generateGiftcloudReward = async (jwt, valueInGBP) => {
  return await performFetchRequest('/admin/generate-giftcloud-reward', {
    method: 'POST',
    headers: {
      'x-access-token': jwt,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      valueInGBP,
    }),
  });
};

export const fetchUnexpiredGiftcloudRewards = async (jwt, page) => {
  return await performFetchRequest(
    `/admin/unexpired-giftcloud-rewards?page=${page}`,
    {
      headers: {
        'x-access-token': jwt,
      },
    }
  );
};

export const fetchGiftcloudRewardsForPlayer = async (jwt, userId) => {
  return await performFetchRequest(
    `/admin/fetch-giftcloud-rewards-for-player?userId=${userId}`,
    {
      headers: {
        'x-access-token': jwt,
      },
    }
  );
};

export const fetchEmailsForPlayer = async (jwt, userId) => {
  return await performFetchRequest(
    `/admin/fetch-emails-for-player?userId=${userId}`,
    {
      headers: {
        'x-access-token': jwt,
      },
    }
  );
};

export const fetchTokensForPlayer = async (jwt, userId) => {
  return await performFetchRequest(
    `/admin/fetch-tokens-for-player?userId=${userId}`,
    {
      headers: {
        'x-access-token': jwt,
      },
    }
  );
};

export const fetchValidTokensForPlayer = async (jwt, userId) => {
  return await performFetchRequest(
    `/admin/fetch-valid-tokens-for-player?userId=${userId}`,
    {
      headers: {
        'x-access-token': jwt,
      },
    }
  );
};

export const fetchTokenTransactionById = async (jwt, txId) => {
  return await performFetchRequest(
    `/admin/fetch-token-transaction?id=${txId}`,
    {
      headers: {
        'x-access-token': jwt,
      },
    }
  );
};

export const fetchTemporaryPromoCodes = async (jwt) => {
  return await performFetchRequest('/admin/temporary-promo-codes', {
    headers: {
      'x-access-token': jwt,
    },
  });
};

export const fetchPermanentPromoCodes = async (jwt) => {
  return await performFetchRequest('/admin/permanent-promo-codes', {
    headers: {
      'x-access-token': jwt,
    },
  });
};

export const deletePromoCode = async (jwt, promoCodeId) => {
  return await performFetchRequest('/admin/delete-promo-code', {
    method: 'POST',
    headers: new Headers({
      'x-access-token': jwt,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      promoCodeId,
    }),
  });
};

export const addPromoCode = async (
  jwt,
  code,
  rewardCode,
  rewardQuantity,
  requirementsCode,
  expiryDate,
  totalRecurrences
) => {
  return await performFetchRequest('/admin/add-promo-code', {
    method: 'POST',
    headers: new Headers({
      'x-access-token': jwt,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      code,
      rewardCode,
      rewardQuantity,
      requirementsCode,
      expiryDate,
      totalRecurrences,
    }),
  });
};

export const fetchCSV = async (jwt, name) => {
  return await performFetchRequest(
    `/admin/csv/${name}`,
    {
      headers: {
        'x-access-token': jwt,
      },
    },
    true
  );
};

export const updatePlayer = async (jwt, userId, field, value) => {
  return await performFetchRequest('/admin/player/update-player-info', {
    method: 'POST',
    headers: new Headers({
      'x-access-token': jwt,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      userId,
      field,
      value,
    }),
  });
};

export const resendEmail = async (jwt, emailId) => {
  return await performFetchRequest(`/admin/resend-email?emailId=${emailId}`, {
    method: 'POST',
    headers: new Headers({
      'x-access-token': jwt,
      'Content-Type': 'application/json',
    }),
  });
};

export const addEmailToBlacklist = async (jwt, email) => {
  return await performFetchRequest(`/admin/add-email-to-blacklist`, {
    method: 'POST',
    headers: new Headers({
      'x-access-token': jwt,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      email
    }),
  });
};

export const removeEmailFromBlacklist = async (jwt, email) => {
  return await performFetchRequest(`/admin/remove-email-from-blacklist`, {
    method: 'POST',
    headers: new Headers({
      'x-access-token': jwt,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      email
    }),
  });
};

export const isEmailBlaclisted = async (jwt, email) => {
  return await performFetchRequest(`/admin/check-email-blacklist?email=${email}`, {
    method: 'GET',
    headers: new Headers({
      'x-access-token': jwt,
      'Content-Type': 'application/json',
    }),
  });
};

export const getPrizeAssigneeOptions = async (jwt) => {
  return await performFetchRequest(`/admin/get-prize-assignee-options`, {
    method: 'GET',
    headers: new Headers({
      'x-access-token': jwt,
      'Content-Type': 'application/json',
    }),
  });
};

export const setDrawPrizeWinAssignee = async (jwt, pwid, assignee_id) => {
  return await performFetchRequest(`/admin/set-draw-prize-assignee`, {
    method: 'POST',
    headers: new Headers({
      'x-access-token': jwt,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      assignee_id,
      pwid
    }),
  });
};

export const setNonDrawPrizeWinAssignee = async (jwt, non_draw_payout_id, assignee_id) => {
  return await performFetchRequest(`/admin/set-non-draw-prize-assignee`, {
    method: 'POST',
    headers: new Headers({
      'x-access-token': jwt,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      assignee_id,
      non_draw_payout_id
    }),
  });
};

export const setRafflePrizeWinAssignee = async (jwt, raffle_prize_win_id, assignee_id) => {
  return await performFetchRequest(`/admin/set-raffle-draw-prize-assignee`, {
    method: 'POST',
    headers: new Headers({
      'x-access-token': jwt,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      assignee_id,
      raffle_prize_win_id
    }),
  });
};

export const setMinilottoPrizeWinAssignee = async (jwt, minilotto_ticket_entry_id, assignee_id) => {
  return await performFetchRequest(`/admin/set-minilotto-draw-prize-assignee`, {
    method: 'POST',
    headers: new Headers({
      'x-access-token': jwt,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      assignee_id,
      minilotto_ticket_entry_id
    }),
  });
};

export const unsetPrizeChoiceOfLotteryPrizeWin = async (jwt, pwid) => {
  return await performFetchRequest(`/admin/unset-prize-choice-of-lottery-prize-win`, {
    method: 'POST',
    headers: new Headers({
      'x-access-token': jwt,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      pwid
    }),
  });
}

export const setDrawPrizeWinFulfilmentStatus = async (jwt, id, newState) => {
  return await performFetchRequest( `/admin/update-fulfilment-of-draw-prize-win`,
    {
      method: 'POST',
      headers: new Headers({
        'x-access-token': jwt,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        id,
        newState
      }),
    }
  );
};

export const setNonDrawPrizeWinFulfilmentStatus = async (jwt, id, newState) => {
  return await performFetchRequest( `/admin/update-fulfilment-of-non-draw-prize-win`,
    {
      method: 'POST',
      headers: new Headers({
        'x-access-token': jwt,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        id,
        newState
      }),
    }
  );
};

export const updateFulfilmentStatusOfRafflePrizeWin = async(jwt, id, newState) =>{
  return await performFetchRequest( `/admin/update-fulfilment-of-raffle-prize-win`,
    {
      method: 'POST',
      headers: new Headers({
        'x-access-token': jwt,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        id,
        newState
      }),
    }
  );
}

export const updateFulfilmentStatusOfMinilottoPrizeWin = async(jwt, id, newState) =>{
  return await performFetchRequest( `/admin/update-fulfilment-of-minilotto-prize-win`,
    {
      method: 'POST',
      headers: new Headers({
        'x-access-token': jwt,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        id,
        newState
      }),
    }
  );
}